.dashboard_conversations .user_thumbnail {
  width: 60px;
  float: left;
}

.dashboard_conversations .user_thumbnail .circle_thumbnail {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.dashboard_conversations .user_thumbnail .user_name {
  display: block;
  font-weight: 500;
  margin: 5px 0 0 0;
  text-align: center;
}

.dashboard_conversations .text_wrapper {
  width: -webkit-calc(100% - 160px);
  width: calc(100% - 160px);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  float: left;
}

.dashboard_conversations .conversations {
  margin: 30px 0 0 0;
}

.dashboard_conversations .conversations .dialog_item {
  margin: 0 0 30px 0;
}

.dashboard_conversations .conversations .dialog_item:last-of-type {
  margin: 0;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  margin: 0 0 0 20px;
  padding: 15px 0 15px 10px;
}

.input_wrapper {
  display: block;
  position: relative;
}

.upload_file_wrapper {
  position: relative;
}

.upload_file_wrapper input[type=file] {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload_file_wrapper .upload_button {
  position: absolute;
  width: 160px;
  bottom: 0;
  cursor: pointer;
  height: 49px;
  background: #3f3f3f;
  color: #fff;
  line-height: 1;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-family: acumin-pro-condensed;
  font-size: 17.5px;
  line-height: 17.5px;
  right: 0;
}

.upload_file_wrapper .upload_button span {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.upload_file_wrapper .upload_button span i {
  margin: 0 5px 0 0;
}

.upload_file_wrapper .upload_button:hover {
  background: #797979;
}

.org_btn {
  border: none;
  color: #fff;
  background: #ff6a10;
  background: -webkit-linear-gradient(top, #ff6a10 0, #ff6a10 50%, #ff5d0e 51%, #ff5d0e 100%);
  background: linear-gradient(to bottom, #ff6a10 0, #ff6a10 50%, #ff5d0e 51%, #ff5d0e 100%);
  -webkit-transition: box-shadow .3s;
  transition: box-shadow .3s;
  font-family: acumin-pro-condensed;
  font-size: 15px;
  line-height: 15px;
  padding: 15px 0 15px 0;
}

.org_btn:hover {
  color: #fff;
  background: #ff5d0e;
  box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, .2);
}

.input_wrapper {
  position: relative;
}

.dashboard_conversations .user_thumbnail {
  width: 60px;
}

.dashboard_conversations .user_thumbnail .circle_thumbnail {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.dashboard_conversations .user_thumbnail .user_name {
  display: block;
  font-weight: 500;
  margin: 5px 0 0 0;
  text-align: center;
}

.dashboard_conversations .text_wrapper {
  width: -webkit-calc(100% - 160px);
  width: calc(100% - 160px);
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap {
  margin: 0 0 5px 0;
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap .label_entry {
  float: left;
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap .label_entry .input_exp {
  margin: 5px 0 0 0;
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap:after {
  clear: both;
  content: '';
  display: block;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap {
  margin-top: 15px;
  padding-top: 58px;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap:after {
  clear: both;
  display: block;
  content: '';
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .upload_btn_wrapper {
  float: none;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .upload_btn_wrapper .upload_button {
  top: 0;
  height: auto;
  bottom: auto;
  right: 120px;
  text-align: center;
  font-family: acumin-pro-condensed;
  font-size: 15px;
  line-height: 15px;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .upload_btn_wrapper .upload_button span {
  position: static;
  display: block;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  padding: 13.5px 0 13.5px 0;
}

.dashboard_conversations .send_msg .user_thumbnail {
  float: left;
}

.dashboard_conversations .send_msg .msg_form {
  margin: 0 0 0 20px;
  padding: 0 0 0 10px;
  float: left;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper {
  background: #f6f6f6;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper textarea {
  resize: none;
  background: 0 0;
  height: 100px;
  width: 100%;
  border: none;
  padding: 15px 30px 15px 30px;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper textarea:focus {
  outline: 0;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper:after {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #f6f6f6;
  content: '';
  top: 20px;
  right: -webkit-calc(100% - 1px);
  right: calc(100% - 1px);
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper:before {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #ddd;
  content: '';
  top: 20px;
  right: 100%;
}

.dashboard_conversations .send_msg .msg_form .submit_wrapper {
  top: 0;
  position: absolute;
  right: 0;
}

.dashboard_conversations .send_msg:after {
  clear: both;
  display: block;
  content: '';
}

@media screen and (max-width:480px) {
  .dashboard_conversations .text_wrapper {
    width: auto;
    float: none!important;
  }

  .dashboard_conversations .send_msg {
    position: fixed;
    bottom: 0;
    z-index: 9;
    background: #f6f6f6;
    width: 100%;
    right: 0;
    padding: 10px 10px 10px 10px;
  }

  .dashboard_conversations .send_msg .user_thumbnail .circle_thumbnail {
    width: 50px;
    height: 50px;
  }

  .dashboard_conversations .send_msg .user_thumbnail .user_name {
    display: none;
  }

  .dashboard_conversations .send_msg .msg_form {
    float: left!important;
    width: -webkit-calc(100% - 60px);
    width: calc(100% - 60px);
    margin: 0;
  }
  .dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded {
    padding: 0 30px;
    margin-top: 30px;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded h3 {
    color: #ff6a10;
    font-family: acumin-pro-condensed;
    font-size: 17.5px;
    line-height: 17.5px;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul {
    margin: 15px -5px 0 -5px;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li {
    padding: 0 5px;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li a {
    padding: 5px;
    height: 150px;
    display: block;
    overflow: hidden;
    color: #ff6a10;
    position: relative;
    border-radius: 3px;
    text-decoration: none;
    border: 1px solid #dedede;
    text-align: center;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li a span {
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
    position: absolute;
    background: #fff;
    text-align: center;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li a i {
    font-size: 40px;
    margin-top: 30px;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper a {
    color: #ff6a10;
    text-decoration: underline;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper a:hover {
    text-decoration: none;
  }
  /*! CSS Used fontfaces */
  @font-face {
    font-family: icomoon;
    src: url(https://ollami.com/assets/web/fonts/icomoon.eot?pqayit);
    src: url(https://ollami.com/assets/web/fonts/icomoon.eot?pqayit#iefix) format('embedded-opentype'), url(https://ollami.com/assets/web/fonts/icomoon.ttf?pqayit) format('truetype'), url(https://ollami.com/assets/web/fonts/icomoon.woff?pqayit) format('woff'), url(https://ollami.com/assets/web/fonts/icomoon.svg?pqayit#icomoon) format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: acumin-pro-condensed;
    src: url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/l?subset_id=2&fvd=n7&v=3) format("woff2"), url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/d?subset_id=2&fvd=n7&v=3) format("woff"), url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/a?subset_id=2&fvd=n7&v=3) format("opentype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: acumin-pro-condensed;
    src: url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/l?subset_id=2&fvd=i7&v=3) format("woff2"), url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/d?subset_id=2&fvd=i7&v=3) format("woff"), url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/a?subset_id=2&fvd=i7&v=3) format("opentype");
    font-weight: 700;
    font-style: italic;
  }

  .dashboard_conversations .send_msg .msg_form form {
    position: relative;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper {
    background: #fff;
    line-height: 0;
    padding: 0 55px 0 0;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper textarea {
    height: 50px;
    line-height: 1.3;
    padding: 15px 0 15px 5px;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper:after {
    border-right-color: #fff;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper:after,
  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper:before {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .dashboard_conversations .send_msg .msg_form form .submit_wrapper {
    position: absolute;
    top: 5px;
    height: -webkit-calc(100% - 10px);
    height: calc(100% - 10px);
    right: 5px;
    margin: 0;
  }

  .dashboard_conversations .send_msg .msg_form form .submit_wrapper input {
    font-size: 0;
    background-color: #7dcdcd;
    background-color: -moz-linear-gradient(top, #7dcdcd 0, #7dcdcd 50%, #6ec7c7 51%, #6ec7c7 100%);
    background-color: -webkit-linear-gradient(top, #7dcdcd 0, #7dcdcd 50%, #6ec7c7 51%, #6ec7c7 100%);
    background-color: linear-gradient(to bottom, #7dcdcd 0, #7dcdcd 50%, #6ec7c7 51%, #6ec7c7 100%);
    background-image: url(https://ollami.com/assets/web/images/angle-arrow-pointing-to-right@2x.png);
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
    height: 100%;
    width: 50px;
    padding: 0;
  }
}


/*! CSS Used fontfaces */

@font-face {
  font-family: icomoon;
  src: url(https://ollami.com/assets/web/fonts/icomoon.eot?pqayit);
  src: url(https://ollami.com/assets/web/fonts/icomoon.eot?pqayit#iefix) format('embedded-opentype'), url(https://ollami.com/assets/web/fonts/icomoon.ttf?pqayit) format('truetype'), url(https://ollami.com/assets/web/fonts/icomoon.woff?pqayit) format('woff'), url(https://ollami.com/assets/web/fonts/icomoon.svg?pqayit#icomoon) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/l?subset_id=2&fvd=n7&v=3) format("woff2"), url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/d?subset_id=2&fvd=n7&v=3) format("woff"), url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/a?subset_id=2&fvd=n7&v=3) format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/l?subset_id=2&fvd=i7&v=3) format("woff2"), url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/d?subset_id=2&fvd=i7&v=3) format("woff"), url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/a?subset_id=2&fvd=i7&v=3) format("opentype");
  font-weight: 700;
  font-style: italic;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .top_dialog {
  position: relative;
  padding: 0 50px 0 30px;
  margin: 0 0 10px 0;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .top_dialog span.date {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1%;
  font-size: 12px;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .top_dialog:after {
  display: block;
  clear: both;
  content: '';
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .body_dialog {
  font-style: italic;
  margin: 0 0 25px 0;
  padding: 0 30px 0 30px;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .body_dialog.pre {
  white-space: pre-line;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .body_dialog:last-of-type {
  margin: 0;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper:after {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #fff;
  content: '';
  top: 20px;
  right: -webkit-calc(100% - 1px);
  right: calc(100% - 1px);
}
.dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li {
  padding: 0 5px;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li a {
  padding: 5px;
  height: 150px;
  display: block;
  overflow: hidden;
  color: #ff6a10;
  position: relative;
  border-radius: 3px;
  text-decoration: none;
  border: 1px solid #dedede;
  text-align: center;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li a span {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  position: absolute;
  background: #fff;
  text-align: center;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li a i {
  font-size: 40px;
  margin-top: 30px;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper a {
  color: #ff6a10;
  text-decoration: underline;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper a:hover {
  text-decoration: none;
}


/*! CSS Used fontfaces */

@font-face {
  font-family: icomoon;
  src: url(https://ollami.com/assets/web/fonts/icomoon.eot?pqayit);
  src: url(https://ollami.com/assets/web/fonts/icomoon.eot?pqayit#iefix) format('embedded-opentype'), url(https://ollami.com/assets/web/fonts/icomoon.ttf?pqayit) format('truetype'), url(https://ollami.com/assets/web/fonts/icomoon.woff?pqayit) format('woff'), url(https://ollami.com/assets/web/fonts/icomoon.svg?pqayit#icomoon) format('svg');
  font-weight: 400;
  font-style: normal;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper:before {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #ddd;
  content: '';
  top: 20px;
  right: 100%;
}

.dashboard_conversations .conversations .dialog_item.my_msg .user_thumbnail {
  float: left;
}

.dashboard_conversations .conversations .dialog_item.my_msg .text_wrapper {
  position: relative;
  padding: 15px 10px 15px 0;
  float: left;
}

.dashboard_conversations .conversations .dialog_item.my_msg .text_wrapper .body_dialog {
  padding: 0 50px 0 30px;
}

.dashboard_conversations .conversations .dialog_item.add_comments .text_wrapper .body_dialog {
  font-style: normal;
}

.dashboard_conversations .conversations .dialog_item:after {
  display: block;
  clear: both;
  content: '';
}

@media screen and (max-width:480px) {
  .dashboard_conversations .text_wrapper {
    width: auto;
    float: none!important;
  }

  .dashboard_conversations .conversations .dialog_item .user_thumbnail {
    display: none;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper {
    margin: 0 10px 0 10px;
  }
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded h3 {
  margin: 0 20px;
  color: #ff6a10;
  font-family: acumin-pro-condensed;
  font-size: 17.5px;
  line-height: 17.5px;
}


/*! CSS Used fontfaces */

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/l?subset_id=2&fvd=n7&v=3) format("woff2"), url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/d?subset_id=2&fvd=n7&v=3) format("woff"), url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/a?subset_id=2&fvd=n7&v=3) format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/l?subset_id=2&fvd=i7&v=3) format("woff2"), url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/d?subset_id=2&fvd=i7&v=3) format("woff"), url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/a?subset_id=2&fvd=i7&v=3) format("opentype");
  font-weight: 700;
  font-style: italic;
}

.dashboard_conversations .user_thumbnail {
  width: 60px;
}

.dashboard_conversations .user_thumbnail .circle_thumbnail {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.dashboard_conversations .user_thumbnail .user_name {
  display: block;
  font-weight: 500;
  margin: 5px 0 0 0;
  text-align: center;
}

.dashboard_conversations .text_wrapper {
  width: -webkit-calc(100% - 160px);
  width: calc(100% - 160px);
}

.dashboard_conversations .conversations .dialog_item {
  margin: 0 0 30px 0;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  margin: 0 0 0 20px;
  padding: 15px 0 15px 10px;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .top_dialog {
  position: relative;
  padding: 0 50px 0 30px;
  margin: 0 0 10px 0;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .top_dialog span.date {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .top_dialog:after {
  display: block;
  clear: both;
  content: '';
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .body_dialog {
  font-style: italic;
  margin: 0 0 25px 0;
  padding: 0 30px 0 30px;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .body_dialog.pre {
  white-space: pre-line;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper .body_dialog:last-of-type {
  margin: 0;
}

.dashboard_conversations .conversations .dialog_item .text_wrapper:after {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #fff;
  content: '';
  top: 20px;
  right: -webkit-calc(100% - 1px);
  right: calc(100% - 1px);
}

.dashboard_conversations .conversations .dialog_item .text_wrapper:before {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #ddd;
  content: '';
  top: 20px;
  right: 100%;
}

.dashboard_conversations .conversations .dialog_item.from_outside .user_thumbnail {
  float: right;
}

.dashboard_conversations .conversations .dialog_item.from_outside .text_wrapper {
  margin: 0 20px 0 0;
  padding: 15px 10px 15px 0;
  float: right;
}

.dashboard_conversations .conversations .dialog_item.from_outside .text_wrapper:after {
  border-right-color: transparent;
  border-left-color: #fff;
  right: auto;
  left: -webkit-calc(100% - 1px);
  left: calc(100% - 1px);
}

.dashboard_conversations .conversations .dialog_item.from_outside .text_wrapper:before {
  position: absolute;
  border-right-color: transparent;
  border-left-color: #ddd;
  right: auto;
  left: 100%;
}


.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog {
  padding: 0 125px 0 30px;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog span.date {
  font-weight: 700;
  right: 55px;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog span.new {
  font-weight: 100;
  position: absolute;
  background: #7dcdcd;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  font-family: proxima-nova;
  font-size: 12px;
  line-height: 12px;
  padding: 5px 5px 5px 5px;
  right: 0;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog span.new:after {
  content: '';
  border: 5px solid transparent;
  border-right-color: #7dcdcd;
  border-bottom-color: #7dcdcd;
  position: absolute;
  bottom: 0;
  right: 100%;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog span.new:before {
  content: '';
  border: 5px solid transparent;
  border-right-color: #7dcdcd;
  border-top-color: #7dcdcd;
  position: absolute;
  top: 0;
  right: 100%;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper:after {
  border-left-color: #f6f6f6;
}

.dashboard_conversations .conversations .dialog_item:after {
  display: block;
  clear: both;
  content: '';
}

@media screen and (max-width:480px) {
  .dashboard_conversations .text_wrapper {
    width: auto;
    float: none!important;
  }

  .dashboard_conversations .conversations .dialog_item .user_thumbnail {
    display: none;
  }

  .dashboard_conversations .conversations .dialog_item .text_wrapper {
    margin: 0 10px 0 10px;
  }

  .dashboard_conversations .conversations .dialog_item.from_outside .text_wrapper {
    margin: 0 10px 0 10px;
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family:proxima-nova;src:url(https://use.typekit.net/af/71f83c/00000000000000003b9b093b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),url(https://use.typekit.net/af/71f83c/00000000000000003b9b093b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),url(https://use.typekit.net/af/71f83c/00000000000000003b9b093b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");font-weight:700;font-style:normal;
}

@font-face {
  font-family:proxima-nova;src:url(https://use.typekit.net/af/86b539/00000000000000003b9b093a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff2"),url(https://use.typekit.net/af/86b539/00000000000000003b9b093a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff"),url(https://use.typekit.net/af/86b539/00000000000000003b9b093a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("opentype");font-weight:700;font-style:italic;
}

@font-face {
  font-family:proxima-nova;src:url(https://use.typekit.net/af/4838bd/00000000000000003b9b0934/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"),url(https://use.typekit.net/af/4838bd/00000000000000003b9b0934/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"),url(https://use.typekit.net/af/4838bd/00000000000000003b9b0934/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");font-weight:400;font-style:normal;
}

@font-face {
  font-family:proxima-nova;src:url(https://use.typekit.net/af/6aec08/00000000000000003b9b0935/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff2"),url(https://use.typekit.net/af/6aec08/00000000000000003b9b0935/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff"),url(https://use.typekit.net/af/6aec08/00000000000000003b9b0935/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("opentype");font-weight:400;font-style:italic;
}

@font-face {
  font-family:proxima-nova;src:url(https://use.typekit.net/af/3331e6/00000000000000003b9b0936/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff2"),url(https://use.typekit.net/af/3331e6/00000000000000003b9b0936/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff"),url(https://use.typekit.net/af/3331e6/00000000000000003b9b0936/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("opentype");font-weight:500;font-style:normal;
}

@font-face {
  font-family:proxima-nova;src:url(https://use.typekit.net/af/1cd3b2/00000000000000003b9b0937/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3) format("woff2"),url(https://use.typekit.net/af/1cd3b2/00000000000000003b9b0937/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3) format("woff"),url(https://use.typekit.net/af/1cd3b2/00000000000000003b9b0937/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3) format("opentype");font-weight:500;font-style:italic;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog span.new {
  font-weight: 100;
  position: absolute;
  background: #7dcdcd;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  font-family: proxima-nova;
  font-size: 12px;
  line-height: 12px;
  padding: 5px 5px 5px 5px;
  right: 0;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog span.new:after {
  content: '';
  border: 5px solid transparent;
  border-right-color: #7dcdcd;
  border-bottom-color: #7dcdcd;
  position: absolute;
  bottom: 0;
  right: 100%;
}

.dashboard_conversations .conversations .dialog_item.new_dialog .text_wrapper .top_dialog span.new:before {
  content: '';
  border: 5px solid transparent;
  border-right-color: #7dcdcd;
  border-top-color: #7dcdcd;
  position: absolute;
  top: 0;
  right: 100%;
}


.dashboard_conversations .send_msg .msg_form form .submit_wrapper input.org_btn {
  border: none;
  -webkit-appearance: button;
  cursor: pointer;
  color: #fff;
  background: #ff6a10;
  background: -webkit-linear-gradient(top,#ff6a10 0,#ff6a10 50%,#ff5d0e 51%,#ff5d0e 100%);
  background: linear-gradient(to bottom,#ff6a10 0,#ff6a10 50%,#ff5d0e 51%,#ff5d0e 100%);
  -webkit-transition: box-shadow .3s;
  transition: box-shadow .3s;

  font-size: 15px;
  line-height: 15px;
  outline: 0;
  padding: 15px 42px 15px 42px;
}

.org_btn:hover {
  color: #fff;
  background: #ff5d0e;
  box-shadow: inset 1px 1px 10px 0 rgba(0,0,0,.2);
}

.dashboard_conversations .send_msg .msg_form .submit_wrapper {
  top: 0;
  position: absolute;
  right: 0;
}

@media screen and (max-width:480px) {
  .dashboard_conversations .send_msg .msg_form form .submit_wrapper {
    position: absolute;
    top: 5px;
    height: -webkit-calc(100% - 10px);
    height: calc(100% - 10px);
    right: 5px;
    margin: 0;
  }

  .dashboard_conversations .send_msg .msg_form form .submit_wrapper input {
    font-size: 0;
    background-color: #7dcdcd;
    background-color: -moz-linear-gradient(top,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-color: -webkit-linear-gradient(top,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-color: linear-gradient(to bottom,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-image: url(https://ollami.com/assets/web/images/angle-arrow-pointing-to-right@2x.png);
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
    height: 100%;
    width: 50px;
    padding: 0;
  }
}
input {
  color: inherit;
  font: inherit;
  margin: 0;
}

input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

* {
  box-sizing: border-box;
}

:after,:before {
  box-sizing: border-box;
}

input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

* {
  outline: 0;
}

input {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
}

input:focus {
  outline: 0;
}

input[type=submit] {
  color: #fff;
  padding: 15px 42px 15px 42px;
}

.org_btn {
  border: none;
  color: #fff;
  background: #ff6a10;
  background: -webkit-linear-gradient(top,#ff6a10 0,#ff6a10 50%,#ff5d0e 51%,#ff5d0e 100%);
  background: linear-gradient(to bottom,#ff6a10 0,#ff6a10 50%,#ff5d0e 51%,#ff5d0e 100%);
  -webkit-transition: box-shadow .3s;
  transition: box-shadow .3s;
  font-family: acumin-pro-condensed;
  font-size: 15px;
  line-height: 15px;
  padding: 15px 0 15px 0;
}

.org_btn:hover {
  color: #fff;
  background: #ff5d0e;
  box-shadow: inset 1px 1px 10px 0 rgba(0,0,0,.2);
}

@media screen and (max-width:480px) {
  .dashboard_conversations .send_msg .msg_form form .submit_wrapper input {
    font-size: 0;
    background-color: #7dcdcd;
    background-color: -moz-linear-gradient(top,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-color: -webkit-linear-gradient(top,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-color: linear-gradient(to bottom,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-image: url(https://ollami.com/assets/web/images/angle-arrow-pointing-to-right@2x.png);
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
    height: 100%;
    width: 50px;
    padding: 0;
  }
}

[class^=icon-] {
  font-family: icomoon!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
}

.icon-icons_details:before {
  content: "\e902";
}

.jFiler * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.jFiler {
  font-family: sans-serif;
  font-size: 14px;
  color: #494949;
}

input,textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}

* {
  box-sizing: border-box;
}

:after,:before {
  box-sizing: border-box;
}

input,textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

h2 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-size: 30px;
}

* {
  outline: 0;
}

h2 {
  margin: 0;
}

input {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
}

input:focus {
  outline: 0;
}

input[type=submit] {

}

.input_wrapper {
  display: block;
  position: relative;
}

.upload_file_wrapper {
  position: relative;
}

.upload_file_wrapper input[type=file] {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload_file_wrapper .upload_button {
  position: absolute;
  width: 160px;
  bottom: 0;
  cursor: pointer;
  height: 49px;
  background: #3f3f3f;
  color: #fff;
  line-height: 1;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-family: acumin-pro-condensed;
  font-size: 17.5px;
  line-height: 17.5px;
  right: 0;
}

.upload_file_wrapper .upload_button span {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.upload_file_wrapper .upload_button span i {
  margin: 0 5px 0 0;
}

.upload_file_wrapper .upload_button:hover {
  background: #797979;
}

.org_btn {
  border: none;
  color: #fff;
  background: #ff6a10;
  background: -webkit-linear-gradient(top,#ff6a10 0,#ff6a10 50%,#ff5d0e 51%,#ff5d0e 100%);
  background: linear-gradient(to bottom,#ff6a10 0,#ff6a10 50%,#ff5d0e 51%,#ff5d0e 100%);
  -webkit-transition: box-shadow .3s;
  transition: box-shadow .3s;
  font-family: acumin-pro-condensed;
  font-size: 15px;
  line-height: 15px;
  padding: 15px 0 15px 0;
}

.org_btn:hover {
  color: #fff;
  background: #ff5d0e;
  box-shadow: inset 1px 1px 10px 0 rgba(0,0,0,.2);
}

.input_wrapper {
  position: relative;
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap {
  margin: 0 0 5px 0;
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap .label_entry {
  float: left;
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap .label_entry .input_exp {
  margin: 5px 0 0 0;
}

.dashboard_conversations .multiple_files_input .upload_files_head_wrap:after {
  clear: both;
  content: '';
  display: block;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap {
  margin-top: 15px;
  padding-top: 58px;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap:after {
  clear: both;
  display: block;
  content: '';
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .upload_btn_wrapper {
  float: none;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .upload_btn_wrapper .upload_button {
  top: 0;
  height: auto;
  bottom: auto;
  right: 120px;
  text-align: center;
  font-family: acumin-pro-condensed;
  font-size: 15px;
  line-height: 15px;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .upload_btn_wrapper .upload_button span {
  position: static;
  display: block;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  padding: 13.5px 0 13.5px 0;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper {
  background: #f6f6f6;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper textarea {
  resize: none;
  background: 0 0;
  height: 100px;
  width: 100%;
  border: none;
  padding: 15px 30px 15px 30px;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper textarea:focus {
  outline: 0;
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper:after {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #f6f6f6;
  content: '';
  top: 20px;
  right: -webkit-calc(100% - 1px);
  right: calc(100% - 1px);
}

.dashboard_conversations .send_msg .msg_form .text_area_wrapper:before {
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #ddd;
  content: '';
  top: 20px;
  right: 100%;
}

.dashboard_conversations .send_msg .msg_form .submit_wrapper {
  top: 0;
  position: absolute;
  right: 0;
}

@media screen and (max-width:480px) {
  .dashboard_conversations .send_msg .msg_form form {
    position: relative;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper {
    background: #fff;
    line-height: 0;
    padding: 0 55px 0 0;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper textarea {
    height: 50px;
    line-height: 1.3;
    padding: 15px 0 15px 5px;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper:after {
    border-right-color: #fff;
  }

  .dashboard_conversations .send_msg .msg_form form .text_area_wrapper:after,.dashboard_conversations .send_msg .msg_form form .text_area_wrapper:before {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .dashboard_conversations .send_msg .msg_form form .submit_wrapper {
    position: absolute;
    top: 5px;
    height: -webkit-calc(100% - 10px);
    height: calc(100% - 10px);
    right: 5px;
    margin: 0;
  }

  .dashboard_conversations .send_msg .msg_form form .submit_wrapper input {
    font-size: 0;
    background-color: #7dcdcd;
    background-color: -moz-linear-gradient(top,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-color: -webkit-linear-gradient(top,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-color: linear-gradient(to bottom,#7dcdcd 0,#7dcdcd 50%,#6ec7c7 51%,#6ec7c7 100%);
    background-image: url(https://ollami.com/assets/web/images/angle-arrow-pointing-to-right@2x.png);
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
    height: 100%;
    width: 50px;
    padding: 0;
  }
}


.dashboard_conversations .conversations .dialog_item .text_wrapper .files_uploaded ul li a i {
  font-size: 40px;
  font-family: icomoon;
  margin-top: 30px;
}

.icon-icons_upload:before {
  content: "\e92f";
}
.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper {
  border: 1px solid #ebebeb;
  position: relative;
  text-align: center;
  margin: 0 0 20px 0;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper .remove_file_item {
  z-index: 1;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper .file_item_bg {
  width: 100%;
  height: 220px;
  position: relative;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper .file_item_bg .jFiler-item-thumb-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper .file_item_bg .jFiler-item-thumb-image img {
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  position: absolute;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper .area_wrap {
  padding: 10px 10px 10px 10px;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper a.remove_file_item {
  position: absolute;
  top: 5px;
  color: #ddd;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  border: 1px solid #ddd;
  background: #fff;
  padding: 10px 10px 10px 10px;
  right: 5px;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper a.remove_file_item:hover {
  color: #9d9d9d;
}

.dashboard_conversations .multiple_files_input .upload_files_body_wrap .file_item_wrapper a.remove_file_item i {
  font-size: 22px;
}
