@import 'pages/_login';
@import 'pages/_leaders';
@import 'pages/_conf_images';
@import "pages/_conf_interests";
@import "quotes";


html {
  min-height: 100%;
}

// side bar logo
.page-header.navbar .page-logo .logo-default {
  /*margin-top: 9px;
  height: 52px;
  margin-left: 40px !important;*/
  margin-top: 22px;
  max-width: 120px;
}

.img_background {
  background: url("../images/login_background.png") center center;
  background-size: cover;
  height: 100%;
}


// Custom information block
.custom-info-block {
  margin-top: 3px;
  margin-bottom: 10px;
  background: #EEF1F5;
  float: right;
  padding: 10px 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-right:0px;
}


// list portfolio
  .list-portfolio {
    padding: 0px 15px;

    .items{
      margin-bottom: 20px;
    }

    .item-block {
      background: #EEF1F5;
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      height:300px;
      overflow-y: auto;
      overflow-x: hidden;

      .fixOverlayDiv {
        height: 300px;
        position: relative;
        text-align: center;

        img {
          position: absolute;
          transform: translate(0, -50%);
          top: 50%;
          display: block;
          margin: 0 auto;
        }
      }

      h3 {
          margin: 0;
      }

      .infoside {
        padding-top: 10px;
        padding-bottom: 20px;
      }
    }
  }

