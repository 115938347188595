.thumb-col {
  img {
    width: 200px;
    height: 100px;
  }
}

.int-col {
  input {
    width: 210px;
  }
}
.files-col {
  width: 210px;
}

.fileinput {
  margin-bottom: 30px;
}