
.page-lock {
  margin : 60px auto 30px auto;
  width: 450px;

  .lock-form .form-group .form-control {
    color: #98A7BD;
  }

  .lock-avatar-block {
    margin-left: 20px;
  }

  .page-logo {
    text-align: center;

    img {
      //height: 160px;
    }
  }

  .page-body {
    width: 100%;
    margin-top: 35px;
    background-color: #3a4554;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
  }

  .lock-head {
    display: block;
    background-color: #323d4b;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
    color: #4db3a5;
    -webkit-border-radius: 7px 7px 0 0;
    -moz-border-radius: 7px 7px 0 0;
    -ms-border-radius: 7px 7px 0 0;
    -o-border-radius: 7px 7px 0 0;
    border-radius: 7px 7px 0 0;
  }

  .lock-body {
    display: block;
    margin: 35px;
    overflow: hidden;
  }

  .lock-avatar {
    margin-top: 15px;
    height: 110px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
  }

  .lock-form h4 {
    margin-top: 0px;
    color: #dbe2ea;
    font-size: 18px;
    font-weight: 400;
  }

  .lock-form .form-group {
    margin-top: 20px;
  }

  .lock-form .form-group .form-control {
    background-color: #303a48;
    border: none;
    height: 40px;
    color: #697687;
  }

  .lock-form .form-group .form-control::-moz-placeholder {
    color: #556376;
    opacity: 1;
  }

  .lock-form .form-group .form-control:-ms-input-placeholder {
    color: #556376;
  }

  .lock-form .form-group .form-control::-webkit-input-placeholder {
    color: #556376;
  }

  .lock-form .form-group .form-control:focus {
    background-color: #2b3542;
  }

  .lock-form .form-actions {
    margin-top: 20px;
  }

  .lock-form .form-actions .btn {
    width: 220px;
    font-weight: 600;
    padding: 10px;
  }

  .lock-bottom {
    display: block;
    background-color: #323d4b;
    text-align: center;
    padding-top: 20px;
    color: #98A7BD;
    padding-bottom: 20px;
    -webkit-border-radius: 0 0 7px 7px;
    -moz-border-radius: 0 0 7px 7px;
    -ms-border-radius: 0 0 7px 7px;
    -o-border-radius: 0 0 7px 7px;
    border-radius: 0 0 7px 7px;

    a {
      font-size: 14px;
      font-weight: 400;
      color: #638cac;

      &:hover {
        color: #7ba2c0;
      }
    }
  }

  .page-footer-custom {
    text-align: center;
    padding-top: 40px;
    color: #5f6e83;
  }

  @media (max-width: 768px) {
    & {
      margin: 100px auto;
    }
  }

  @media (max-width: 560px) {
    & {
      margin: 50px auto;
      width: 400px;
    }
    .lock-body {
      margin: 30px;
    }
    .lock-avatar {
      width: 120px;
      height: 120px;
      margin-top: 20px;
    }
    .lock-form {
      h4 {
        font-size: 16px;
      }

      .form-group .form-control {
        padding: 20px 20px;
        width: 170px;
      }

      .form-actions .btn-success {
        width: 170px;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 420px) {
    & {
      margin: 30px auto;
      width: 280px;
    }
    .lock-body {
      margin: 0px;
    }
    & .page-body {
      margin-top: 30px;
    }
    .lock-avatar {
      margin-top: 20px;
    }
    .lock-avatar-block {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
    .lock-form {
      padding-left: 20px;
    }
    .lock-form h4 {
      font-size: 16px;
      text-align: center;
    }
    .lock-form .form-group .form-control {
      padding: 20px 20px;
      width: 240px;
    }
    .lock-form .form-actions .btn-success {
      width: 240px;
      margin-bottom: 20px;
    }
  }
}