.thumbnail {
  display: table;
  width: 100px;
  height: 100px;

  img {
    width: 90px;
    height: 90px;
  }

  .cell {
    display: table-cell;
    vertical-align: middle;

  }
}

.uneditable-input {
  min-width: 10px;

}

.pull-right.plus-button {
  margin-left: 20px;
}

.row.save-button {
  margin-right: 20px;
}